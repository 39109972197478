<template>
  <div>
    <StateRenderer v-bind="rents">
      <b-alert
        v-if="rents.data.length === 0"
        show
        variant="secondary"
        class="text-center"
      >
        Nenhum aluguel realizado neste período
      </b-alert>
      <template v-else>
        <h4 class="d-flex justify-content-between">
          <b> Total: {{ total | formatCurrency }} </b>
          <b> Horas: {{ totalHours.toFixed(2) }} </b>
        </h4>
        <b-table
          :fields="fields"
          :items="rents.data"
          striped
          hover
          :busy="rents.loading"
          responsive
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" />
          </template>
          <template #cell(hours)="{ item }">
            <span class="d-block">
              {{ item.hours.toFixed(2) }}
            </span>
          </template>
          <template #cell(amount)="{ item }">
            <span class="d-block">
              {{ item.amount | formatCurrency }}
            </span>
          </template>
        </b-table>
      </template>
    </StateRenderer>
  </div>
</template>

<script>
import { reduce } from 'lodash/fp'
import { formatCurrency } from '@/helpers/numbers'
import StateRenderer from '@/components/StateRenderer'

export default {
  name: 'RentsPerToyReportList',
  filters: {
    formatCurrency
  },
  components: {
    StateRenderer
  },
  props: {
    rents: {
      type: Object,
      required: true
    }
  },
  computed: {
    fields: () => [
      {
        key: 'toyName',
        label: 'Brinquedo'
      },
      {
        key: 'hours',
        label: 'Horas'
      },
      {
        key: 'amount',
        label: 'Total'
      }
    ],
    total() {
      return reduce(
        (result, rent) => (result += rent.amount),
        0,
        this.rents.data
      )
    },
    totalHours() {
      return reduce(
        (result, rent) => (result += rent.hours),
        0,
        this.rents.data
      )
    }
  }
}
</script>
