<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <RentsPerToyReport />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import RentsPerToyReport from '@/components/Reports/Rents/RentsPerToyReport'

export default {
  name: 'ReportsRentsPerToy',
  components: {
    Layout,
    RentsPerToyReport
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Relatórios',
        to: { name: 'ReportsIndex' }
      },
      {
        text: 'Aluguéis por Brinquedo',
        to: { name: 'ReportsRentsPerToy' },
        active: true
      }
    ]
  }
}
</script>
